<script lang="ts" setup>
import type { NavigationLink } from 'global'

const { t } = useI18n()

const navigation = ref([
  {
    label: t('nav_bible'),
    url: t('nav_bible_url'),
  },
  {
    label: t('nav_feedback'),
    url: t('nav_feedback_url'),
  },
] as NavigationLink[])

const setNav = () => {
  navigation.value = [
    {
      label: t('nav_bible'),
      url: t('nav_bible_url'),
    },
    {
      label: t('nav_feedback'),
      url: t('nav_feedback_url'),
    },
  ]
}

watch(
  () => t('brand'),
  () => {
    setNav()
  },
)
</script>

<template>
  <div class="min-h-screen">
    <ChatWarningModal />
    <LayoutBottomDrawer>
      <template #content>
        <ChatBibleStream />
      </template>
    </LayoutBottomDrawer>
    <LayoutNavBar :navigation="navigation" :show-bible-nav="true" />
    <div class="p-2 md:p-8 mx-auto text-zinc-200">
      <slot />
    </div>
    <LayoutFooterAIButton class="lg:hidden" />
  </div>
</template>
