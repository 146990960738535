<script setup lang="ts">
import nuxtStorage from 'nuxt-storage'

const { t } = useI18n()

const hideModal = () => {
  infoModal.value.close()
  try {
    nuxtStorage.localStorage.setData('hasSeenInfoModel', Date.now(), 7, 'd')
  } catch (e) {
    console.log(e)
  }
}

const infoModal = ref()
onMounted(() => {
  try {
    infoModal.value = document.getElementById('infoModal')
    const hasSeenInfoModel =
      nuxtStorage.localStorage.getData('hasSeenInfoModel') || false
    if (!hasSeenInfoModel) {
      infoModal.value.showModal()
    }
  } catch (e) {
    console.log(e)
  }
})

const carouselItems = ref([
  {
    id: 'item0',
    active: true,
    headerText: t('model_text0_header'),
    middleText: t('model_text0_middle'),
    bottomText: t('model_text0_bottom'),
    image: t('model_text0_image'),
  },
  {
    id: 'item1',
    active: false,
    middleText: t('model_text1'),
    image: t('model_text1_image'),
  },
  {
    id: 'item2',
    active: false,
    headerText: t('model_text2_header'),
    middleText: t('model_text2_middle'),
    image: t('model_text2_image'),
  },
  {
    id: 'item3',
    active: false,
    headerText: t('model_text3_header'),
    middleText: t('model_text3_middle'),
    image: t('model_text3_image'),
  },
  {
    id: 'item4',
    active: false,
    middleText: t('model_text4'),
    image: t('model_text4_image'),
  },
])

const btnText = ref(t('model_next'))
const next = () => {
  carouselItems.value.some((item, index) => {
    if (item.active) {
      if (index === carouselItems.value.length - 1) {
        hideModal()
      } else {
        item.active = false
        carouselItems.value[index + 1].active = true
        if (index + 1 === carouselItems.value.length - 1) {
          btnText.value = t('model_close')
        }
      }
      return true // Stop the iteration
    }
    return false
  })
}
</script>
<template>
  <dialog id="infoModal" class="modal">
    <div class="modal-box bg-white dark:bg-white dark:text-black">
      <div class="flex flex-col items-center text-center">
        <h3 class="font-bold text-lg">{{ $t('ai_assistant') }}</h3>

        <div
          v-for="(item, index) in carouselItems"
          :id="item.id"
          :key="index"
          class="carousel-item w-full flex flex-col items-center"
          :class="item.active ? 'block' : 'hidden'"
        >
          <img
            :src="item.image"
            alt="AI Assistant"
            class="-mb-12 object-scale-down w-full h-auto"
          />

          <p class="pb-4 font-semibold">
            {{ item.headerText }}
          </p>
          <p class="py-4">
            {{ item.middleText }}
          </p>
          <p class="py-4">
            {{ item.bottomText }}
          </p>
        </div>

        <button
          class="btn btn-sm btn-info bg-sky-500 btn-md text-white w-full"
          @click="next"
        >
          {{ btnText }}
        </button>
      </div>
    </div>
  </dialog>
</template>
