<script lang="ts" setup>
import { useChatDrawerState } from '~/composables/useChatDrawerState'

const drawerState = useChatDrawerState()

// Toggles drawer state
function toggleDrawer() {
  return (drawerState.value = !drawerState.value)
}

// Handles body overflow
watch(
  () => drawerState.value,
  () => {
    if (typeof window !== 'undefined' && window.document) {
      if (drawerState.value) document.body.style.overflow = 'hidden'
      else document.body.style.overflow = 'unset'
    }
  },
)
</script>

<template>
  <div id="appDrawer" class="drawer" :class="{ show: drawerState }">
    <div class="drawer__overlay rounded-lg" @click="toggleDrawer" />
    <div class="drawer__container rounded-lg">
      <div class="drawer__header flex justify-between m-4">
        <div class="block"></div>
        <div class="text-start flex items-center justify-center">
          <h2 class="text-md font-bold text-gray-800">
            {{ $t('ai_assistant') }}
          </h2>
        </div>
        <div class="flex justify-end items-center">
          <button
            class="btn btn-square btn-ghost bg-transparent btn-xs sm:btn-sm"
            @click="toggleDrawer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-gray-400 dark:text-gray-100"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="drawer__content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<style lang="css" scoped>
.drawer {
  @apply z-50;
  @keyframes show {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }

  &.show {
    .drawer__overlay {
      animation: show 0.5s forwards;
      @apply w-[100vw];
    }

    .drawer__container {
      animation: show 0.5s forwards;
      @apply visible h-full w-[80vw] min-w-[100%] bg-white opacity-100 dark:bg-gray-600;
      /* @apply visible h-[100vh] w-[100vw] min-w-[20rem] bg-white opacity-100 dark:bg-gray-900 sm:w-[100vw] lg:w-[100vw] xl:w-[100vw]; */
      animation: slideUp 0.5s forwards;
    }

    .drawer__content {
      animation: show 0.3s forwards;
      @apply visible opacity-100;
    }
  }

  &__close {
    @apply bg-slate-200 p-4 dark:bg-gray-800 dark:text-white;

    &:hover {
      @apply bg-slate-100 dark:bg-gray-700;
    }
  }

  &__overlay {
    transition: all 0.1s ease-in-out;
    @apply fixed inset-x-0 bottom-0 mx-auto z-50 h-screen w-0;
    background: #00000077;
  }

  &__container {
    transition: all 0.5s ease-in-out;
    @apply inset-x-0 bottom-0 mx-auto w-[100vw] max-w-md h-0 invisible fixed right-0 z-50 flex flex-col overflow-hidden opacity-0;
  }

  &__content {
    transition:
      visibility 0s linear 0.5s,
      opacity 0.2s 0s;
    @apply invisible relative h-auto flex-grow overflow-y-auto opacity-0;
  }
}
</style>
