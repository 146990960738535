<script setup lang="ts">
import { useChatDrawerState } from '~/composables/useChatDrawerState'
import { useBibleStore } from '~/store/bible'
const bibleStore = useBibleStore()

const { selectedVerses } = storeToRefs(bibleStore)
const drawerState = useChatDrawerState()

// Toggles drawer state
function toggleDrawer() {
  return (drawerState.value = !drawerState.value)
}
</script>
<template>
  <section>
    <div class="btm-nav bg-transparent">
      <button
        class="btn btn-lg max-w-72 bg-white dark:text-gray-800 dark:hover:text-gray-100 rounded-full mb-8 shadow-lg"
        @click="toggleDrawer()"
      >
        <div class="flex items-center gap-2 py-4">
          <ChatNBGIcon icon-id="footer-icon" />

          <span class="text-lg font-medium">{{ $t('ai_assistant') }}</span>
          <div
            v-show="selectedVerses && selectedVerses.length > 0"
            class="badge badge-error badge-md h-5 text-white absolute bottom-8 right-0"
          ></div>
        </div>
      </button>
    </div>
  </section>
</template>
